import Loader from "components/loader";
import clsx from "clsx";

export default function Submit(props) {
  const { loading, className, onClick } = props;
  const disabled = props.disabled || loading;

  return (
    <button
      onClick={onClick}
      className={clsx(className, "min-h-[44px]")}
      data-disabled={disabled}
    >
      {loading ? (
        <div className="absolute-center">
          <Loader />
        </div>
      ) : (
        props.children
      )}
    </button>
  );
}
