import * as userApi from "api/users";

import { useUser } from "hooks/user";
import { useQuery } from "react-query";

import { User } from "types/user";

interface ProfileInfo {
  user: User;
  currentStudyPlanStep: number;
  isLoading: boolean;
}

export function useProfile(): ProfileInfo {
  const { user } = useUser();
  const { data: response, isLoading } = useQuery("user", userApi.show, {
    enabled: !!user
  });

  const body = response?.body || {};
  const userInfo = body?.data || null;
  return { user: userInfo, ...body, isLoading };
}
