import Link from "next/link";
import Image from "next/image";
import Closable from "components/behaviors/closable";
import MenuNotifications from "./menu_notifications";

import { FiLoader } from "react-icons/fi";

import { pages } from "utils/navigation";
import { logout } from "utils/auth";
import clsx from "clsx";
import { isPwa } from "utils/browser";
import { currentQuestionPath } from "utils/questionnaire";

import { useState, useEffect } from "react";
import { usePath } from "hooks/navigation";
import { useUser } from "hooks/user";
import { useNotifications } from "hooks/notifications";
import { useQueryClient } from "react-query";
import { useMounted } from "hooks/react";
import { useSubscription } from "hooks/subscription";

import * as stripePortalSessionsApi from "api/stripe_portal_sessions";

interface Props {
  unreadNotificationsBadge: JSX.Element;
  unreadNotificationsCount: number;
  onClose?: () => void;
}

export default function Menu(props: Props): JSX.Element {
  const { unreadNotificationsBadge } = props;
  const { onClose } = props;

  const [showNotifications, setShowNotifications] = useState(false);
  const { user } = useUser();
  const { setNotification } = useNotifications();
  const queryClient = useQueryClient();
  const mounted = useMounted();

  const { isLoading: isLoadingSubscription, isActive: hasActiveSubscription } =
    useSubscription();

  const hasOngoingQuestionnaire = !!currentQuestionPath();

  function AuthOptions() {
    if (user) return <Item title="Sair" onClick={handleLogout} />;

    return (
      <div>
        <Item href="/cadastro" />
        <Item href="/login" />
      </div>
    );
  }

  function handleLogout() {
    logout();
    setNotification({
      type: "success",
      title: "Você saiu da conta",
      text: "Redirecionando para a página inicial..."
    });
    setTimeout(() => {
      window.location.href = "/";
    }, 3000);
  }

  return (
    <div
      className={clsx(
        "application-menu z-[35] overflow-y h-[100dvh] fixed right-0 top-0 p-3 w-72 bg-white shadow-2xl overflow-y-scroll lg:sticky lg:flex-shrink-0 lg:w-60 lg:border-r lg:border-gray-100 lg:shadow-none"
      )}
    >
      <Closable onClose={onClose}>
        {(showNotifications && (
          <MenuNotifications
            onClose={() => setShowNotifications(false)}
            onRead={() => queryClient.invalidateQueries("user")}
          />
        )) || (
          <>
            <div className="mx-4 my-4">
              <Link href={user ? "/plano/tarefas" : "/"}>
                <a>
                  <Image
                    src="/logo.svg"
                    alt="Logo do Vstiba"
                    width={68}
                    height={22}
                  />
                </a>
              </Link>
            </div>
            <ul>
              <Item href="/plano/tarefas" />
              <Item
                href={currentQuestionPath() || "/questoes"}
                notifications={hasOngoingQuestionnaire}
              />
              <Item href="/desempenho" />
              <Item href="/meus-simulados" />
              <Item href="/aulas" />
              <Item href="/contato" />
              {(!mounted || !isPwa()) && <Item href="/aplicativo" />}
              <Item
                title="Notificações"
                className="skip-closable"
                onClick={() => setShowNotifications(true)}
              >
                {unreadNotificationsBadge}
              </Item>

              {(!isPwa() || hasActiveSubscription) && (
                <Item href="/assinatura" />
              )}
              <Item href="/limites/chat" />

              <Item href="/configuracoes" />

              {!process.env.NEXT_PUBLIC_DISABLE_ADS &&
                !isLoadingSubscription &&
                !hasActiveSubscription && <Item href="/remover-anuncios" />}

              <hr className="my-5" />

              <AuthOptions />
            </ul>
          </>
        )}
      </Closable>
    </div>
  );
}

function Item(props) {
  const { href, className, notifications } = props;

  const { isActive: hasActiveSubscription } = useSubscription();

  const section = href && /\/[a-zA-Z\-]*/.exec(href)[0];
  const page = pages.find(
    (page) =>
      (page.path && page.path.indexOf(section) === 0) ||
      page.title == props.title
  );
  const title = props.title || page.title;
  const icon = page.icon;
  const [isActive, setIsActive] = useState(false);
  const activePath = usePath();

  const [loadingStripePortal, setLoadingStripePortal] = useState(false);

  const { setNotification } = useNotifications();

  useEffect(() => {
    if (!href) return;
    setIsActive(activePath == section);
  }, [activePath, href]);

  function bgColor() {
    if (isActive) return "bg-primary-50";
    return "hover:bg-gray-50 active:bg-gray-100";
  }

  function textColor() {
    if (isActive) return "text-primary-700";
    return "text-emphasis-medium";
  }

  function iconColor() {
    if (isActive) return "text-primary-700";
    return "text-emphasis-medium";
  }

  function handleClick(event) {
    if (href === "/assinatura" && hasActiveSubscription) {
      event.preventDefault();
      return handleSubscriptionClick();
    }

    !href && event.preventDefault();
    page.onClick && page.onClick();
  }

  function handleSubscriptionClick() {
    setLoadingStripePortal(true);
    return stripePortalSessionsApi
      .create(window.location.href)
      .then((response: any) => {
        window.location.href = response.body.data.sessionUrl;
      })
      .catch((error: any) => {
        setNotification({
          type: "error",
          title: "Ocorreu um erro",
          text: 'Por favor <a href="/contato">entre em contato</a> para gerenciar sua assinatura'
        });
        throw error;
      })
      .finally(() => {
        setLoadingStripePortal(false);
      });
  }

  return (
    <li
      className={clsx("last:mb-0 mb-2", { [className]: className })}
      onClick={() => props.onClick && props.onClick()}
    >
      <Link href={href || "#"}>
        <a
          className={clsx(
            "relative flex items-center px-3 py-2 no-underline font-semibold rounded-md cursor-pointer transition-colors",
            bgColor(),
            textColor(),
            { "animate-pulse": href == "/assinatura" }
          )}
          onClick={handleClick}
        >
          {props.children}
          {notifications && (
            <div className="w-[6px] h-[6px] absolute right-3 top-1/2 bg-secondary-400 rounded-full transform -translate-y-1/2"></div>
          )}
          <div
            className={clsx(`mr-3 ${iconColor()}`, {
              "animate-spin": loadingStripePortal
            })}
          >
            {(loadingStripePortal && <FiLoader />) || icon({})}
          </div>
          {title}
        </a>
      </Link>
    </li>
  );
}
